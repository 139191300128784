.wrapper {
  padding: 50px 0;
  background:#f7f7f7;
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing2);
}

.article {
  flex: 1 1 300px;
  min-width: 200px;
  display: flex;
  flex-direction: column;

  background: white;

  box-shadow: 0 2px 3px rgb(0 0 0 / 5%);
  gap: var(--spacing2);
  /* border-radius: 3px; */
  overflow: hidden;
  color: inherit;
  text-decoration: none;
}

.image {
  height: 250px;
  position: relative;
  transition: 0.2s;

  background-color: #f26f21;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23FEBA00'/%3E%3Cstop offset='1' stop-color='%23F26F21'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23F2EA3C' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23F2EA3C' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.5'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  @nest .article:hover &, .article:focus & {
    filter: brightness(1.3);
  }

  & img {
    object-fit: cover;
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.articleTitle {
  padding: 0 var(--spacing2) var(--spacing2);
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.2s;

  font-size: 18px;
  font-weight: 500;

  @nest .article:hover &, .article:focus & {
    color: var(--primary);
  }
}

.moreArticles {
  display: flex;
  place-items: center;

  appearance: none;
  background: var(--primary);
  color: white;
  padding: 0 30px;
  height: 50px;
  border: none;
  width: fit-content;
  min-width: 250px;
  place-content: center;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 3px;
  margin: 50px auto 0;
  text-decoration: none;

  transition: 0.2s;

  /* box-shadow: 0px 2px 0px var(--primary-600), 0px 0px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); */

  &:hover,
  &:focus {
    filter: brightness(1.05);

    /* box-shadow: 0px 3px 0px var(--primary-600), 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px); */
  }

  &:active {
    filter: brightness(1.1);

    /* box-shadow: 0px 0px 0px var(--primary-600), 0px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(0px); */
  }
}
