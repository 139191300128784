.wrapper {
  background: #111;
  color: white;
  padding: 40px 0;
  position: relative;

  z-index: 2;
  // margin-top: 50px;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // align-items: center;
  text-align: center;
}

.title {
  display: flex;
  flex: 1 1 300px;
  margin-bottom: 40px;
  // place-items: center;
  flex-direction: column;
  // place-content: space-between;
  align-items: center;
}

.tagline {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 100;
}

.logo {
  width: 150px;
  // height: 100px;
  // position: relative;
}

.list {
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  align-items: center;
  justify-content: flex-start;
}

.footerTitle {
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}

.footerLink {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  place-items: center;
  line-height: 1;
  padding: 10px 0;

  i {
    margin-left: 15px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.footerBottom {
  display: flex;
  flex-direction: row;
  place-items: center;
  place-content: center;
  padding: 40px 0;
  font-size: 20px;
  font-weight: 200;
  color: white;
  background: black;
}
