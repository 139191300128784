:root {
  --primary-50: hsl(22deg 89% 95%);
  --primary-100: hsl(22deg 89% 90%);
  --primary-200: hsl(22deg 89% 80%);
  --primary-300: hsl(22deg 89% 70%);
  --primary-400: hsl(22deg 89% 60%);
  --primary-500: hsl(22deg 89% 50%);
  --primary-600: hsl(22deg 89% 40%);
  --primary-700: hsl(22deg 89% 30%);

  --primary: #f26f21;
  --secondary: #feba00;

  --secondary-500: hsl(44deg 100% 50%);
  --secondary-600: hsl(44deg 100% 40%);
  --secondary-700: hsl(44deg 100% 30%);

  --spacing1: 10px;
  --spacing2: 20px;
  --spacing3: 30px;
  --spacing4: 40px;
  --spacing5: 50px;

  --itemsPerRow: 3;
}

html,
body {
  font-family: 'Oswald', sans-serif;
  overflow-x: hidden;
  overflow-x: clip;

  --maxWidth: min(100vw - 80px, 1220px);
  --scrollPadding: calc((100vw - var(--maxWidth)) / 2);

  @media (--mobile) {
    --maxWidth: min(100vw - 40px, 1220px);
  }
}

span[style^='color'] {
  color: inherit !important;
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48;

  font-display: block;
  max-width: 24px;
  overflow: hidden;
}

[data-reactroot] {
  height: 100%;
  position: relative;
}

.container {
  max-width: 1300px;
  padding: 0 var(--spacing2);
  margin: 0 auto;

  @media (--tablet) {
    padding: 0 var(--spacing4);
  }

  @media (--desktop) {
    padding: 0 var(--spacing4);
  }

  &[data-is-spaced='true'] {
    margin: 50px auto;

    @media (--mobile) {
      margin: 30px 0;
    }
  }
}

p,
ul {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 1.8;
  margin-top: var(--spacing2);
  color: #808184;

  @media (--mobile) {
    font-size: 14px;
    line-height: 1.8;
    margin-top: 15px;
  }

  &:first-child {
    margin-top: 0;
  }

  & a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 500;
  }

  & h3 + & {
    margin-top: var(--spacing1);
  }

  & + h2,
  & + h3 {
    margin-top: var(--spacing2);
  }

  i {
    font-style: italic;
  }
}

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 20px;

  & > li:before {
    position: absolute;
    content: '-';
    width: 13px;
    margin-left: -20px;
    text-align: right;
  }

  & > li {
    & + li {
      margin-top: var(--spacing1);
    }
  }
}

.button {
  --textColor: white;
  --mainColor: var(--primary);
  --shadowColor: var(--primary-600);

  display: flex;
  appearance: none;
  background: var(--mainColor);
  color: var(--textColor);
  padding: 0 30px;
  height: 50px;
  border: none;
  width: fit-content;
  min-width: 250px;
  place-items: center;
  place-content: center;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 3px;
  user-select: none;
  /* margin: 0 auto; */
  text-decoration: none;
  /* text-transform:uppercase; */
  transition: 0.2s;
  /* width:100%; */

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* box-shadow: 0px 2px 0px var(--shadowColor), 0px 0px 5px rgba(0, 0, 0, 0.2); */
  /* transform: translateY(-2px); */

  &:hover,
  &:focus {
    filter: brightness(1.05);

    /* box-shadow: 0px 3px 0px var(--shadowColor), 0px 0px 10px rgba(0, 0, 0, 0.2); */
    /* transform: translateY(-3px); */
  }

  &:active {
    filter: brightness(1.1);

    /* box-shadow: 0px 0px 0px var(--shadowColor), 0px 0px 2px rgba(0, 0, 0, 0.2); */
    /* transform: translateY(0px); */
  }

  & + & {
    margin-top: -20px;

    @media (--mobile) {
      margin-top: -10px;
    }
  }

  &[data-size='large'] {
    height: 70px;
    min-width: 300px;
    font-size: 24px;
    font-weight: 400;

    @media (--mobile) {
      height: 50px;
      font-size: 20px;
      font-weight: 400;
    }
  }

  &[data-theme='secondary'] {
    --mainColor: var(--secondary);
    --shadowColor: var(--secondary-600);
  }

  &[data-theme='grey'] {
    --textColor: #999;
    --mainColor: #f7f7f7;
    --shadowColor: hsl(0deg 0% 85%);
  }
}

[data-opacity] {
  opacity: 0;
  transition: 0.2s linear;

  &[data-opacity='true'] {
    opacity: 1;
  }
}
