.wrapper {
  top: 0px;
  position: fixed;

  z-index: 100;
  background: white;
  left: 0;
  right: 0;
  flex-direction: column;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);

  transition: 0.2s;

  &[data-is-homepage='true'] {
    /* opacity: 0;
    transform: translateY(-60px); */

    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 3px rgb(0 0 0 / 0%);
    color: white;

    @media (--mobile) {
      transform: translateY(0);
      opacity: 1;
    }

    & .menuItem {
      color: white;

      &:hover {
        background: white;
        color: black;
      }
    }

    & .logo {
      height: 40px;
      width: auto;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      transition: 0.4s;

      & path {
        transition: 0.2s;
        fill: #fff;
      }
    }

    &[data-is-sidebar-active='true'] {
      & .menuButton, & .search {
        color: black;
      }
    }
  }
}

.headerTop {
  left: 0;
  right: 0;
  flex-direction: column;
}

.headerBottom {
  left: 0;
  right: 0;
}

.headerBottomInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 40px;
  width: auto;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.1s;

  & path {
    fill: #000;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.search {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    transform: scale(1.1);
  }
}

.menuButton {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.1s;
  display: flex;
  place-content: center;
  place-items: center;
  padding: 0;
  margin-right: -8px;

  /* @media (--desktopMin) {
    display: none;
  } */

  &:hover {
    transform: scale(1.1);
  }
}

.menu {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-right: auto;
  gap: 3px;
  white-space: nowrap;
  user-select: none;
}

.menuItem {
  /* padding: 0 var(--spacing2); */
  height: 60px;
  place-content: center;
  place-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.1s;
  /* border-radius: 3px; */
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s;
  text-decoration: none;
  color: black;
  position: relative;

  & > a {
    padding: 0 var(--spacing2);
    height: 60px;
    place-content: center;
    place-items: center;
    display: flex;
    text-decoration: none;
    color:inherit;
  }

  @media (--tablet) {
    &:nth-child(n + 5) {
      display: none;
    }
  }

  @media (--mobile) {
    display: none;
  }

  &:is(:hover, :focus, :focus) {
    outline: none;
    color: var(--primary);
  }

  &:is(:hover, :focus, :focus-within) {
    color: var(--primary);

    & .subMenuItems {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }

  &[data-is-active='true'] {
    color: var(--primary);
  }
}

.subMenuItems {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  background: white;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: 0.1s;
  transform: translateY(-5px);
  width: fit-content;

  box-shadow: 0px -3px 0px white, 0px 0px 3px rgb(0 0 0 / 10%);

  & .subMenuItem {
    height: 40px;
    place-items: center;
    display: flex;
    color: black;
    text-decoration: none;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    transition: 0.1s;
    position:relative;

    & a {
      padding: 0 var(--spacing2);
      height: 40px;
      color:inherit;
      text-decoration: none;
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
    }


    &[data-is-active='true'] {
      color: var(--primary);
    }

    &:active,
    &:hover,
    &:focus {
      outline: none;
      color: var(--primary);
      
      & .thirdItems {
        opacity: 1;
        transform: translateY(40px);
        pointer-events: all;

      }
    }

    & .thirdItems {
      background: #f7f7f7;
      background: #fff;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      color:black;
      top: 0px;
      left:0;
      display: flex;
      flex-direction: column;
      transition: 0.1s;
      transform: translateY(calc(40px - 5px));
      /* width: fit-content; */
      text-align: left;
      right: 0;

      & a {
        color:inherit;
        text-decoration: none;
        padding: 0 var(--spacing2);
        padding-left: 30px;
        height: 40px;
        place-items: center;
        /* place-content: center; */
        display: flex;
        width: 100%;
        text-align: left;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}

.getInTouch {
  display: flex;
  place-content: center;
  place-items: center;
  padding: 0 15px;
  height: 40px;
  color: white;
  white-space: nowrap;

  background: var(--primary);
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transform: scale(1);
  text-decoration: none;

  transition: 0.1s ease-in-out;

  &[data-is-active='true'] {
    background: white;
    color: var(--primary);

    &::after {
      border-color: white transparent transparent transparent;
    }
  }

  &::after {
    content: '';
    position: absolute;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: var(--primary) transparent transparent transparent;
    transition: 0.1s ease-in-out;

    bottom: -6px;
    right: 10px;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    background: var(--secondary);

    &::after {
      border-color: var(--secondary) transparent transparent transparent;
    }
  }
}

.chevron {
  width: 24px;
  height: 24px;

  position: relative;
  top: 2px;
  max-width: unset;
}