.wrapper {
  position: fixed;
  z-index: 100;

  place-items: center;

  display: flex;
  flex-direction: row-reverse;
  top: 40px;
  right: 40px;

  color: black;

  gap: 0 40px;
}

.sidebarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 89;
  opacity: 0;
  pointer-events: none;

  transition: 0.2s ease-in-out;

  &[data-is-active='true'] {
    pointer-events: all;
    opacity: 1;
  }
}

.sidebar {
  position: fixed;
  z-index: 90;
  top: 0;
  right: 0;
  width: 0;

  user-select: none;
  /* background: var(--primary);
  background: linear-gradient(to top, var(--secondary), var(--primary), var(--primary-600)); */

  background-color: #fff;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23F26F21' stroke-width='70.8' stroke-opacity='0.28' %3E%3Ccircle fill='%23FEBA00' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23feb604' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23fdb208' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23fcad0c' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23fca90f' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23fba511' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23fba113' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%23fa9c15' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23f99817' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23f99419' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23f88f1a' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23f78b1b' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23f6861c' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23f5821e' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23f57d1e' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23f4791f' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23f37420' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23F26F21' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover; */

  overflow: hidden;
  display: flex;
  flex-direction: column;
  place-content: flex-start;
  transform: translateX(100px);
  width: 380px;
  max-width: 100vw;
  height: 100vh;
  padding: 80px 30px 30px;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
  color: black;
  text-transform: uppercase;
  font-size: 18px;

  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &[data-is-active='true'] {
    pointer-events: all;
    transform: translateX(0);
    opacity: 1;
  }
}

.sidebarLink {
  line-height: 2;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 0 10px; */
  flex-wrap: wrap;
  width: 100%;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  will-change: transform, color;

  &[data-is-active='true'] {
    color: var(--primary);
  }

  & i {
    padding: 0 10px;
    /* color: black; */
  }

  & .caret {
    padding: 0;
    margin-right: 10px;
    will-change: transform, color;
    /* transition: 0.2s; */

    &[data-is-active='true'] {
      /* color: var(--primary); */
      transform: rotate(90deg);
    }
  }

  &:hover {
    color: var(--primary);
  }
}

.subMenuItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 1 100%;
  color: black;
  opacity: 0;
  pointer-events: none;
  height: 0;
  transform: translateX(10px);

  will-change: opacity, transform;

  &[data-is-active='true'] {
    transition: 0.4s;
    opacity: 1;
    pointer-events: all;
    transform: translateX(20px);
    height: auto;
  }
}

.subMenuItem {
  color: black;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    color: var(--primary);
  }
}
