.wrapper {
  margin: 50px 0;

  @media (--mobile) {
    margin: 30px 0;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing5);
  align-items: center;
  place-content: center;

  @media (--mobile) {
    gap: var(--spacing2);
  }
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  margin-bottom: 30px;

  @media (--tabletMax) {
    font-size: 20px;
  }
}

.client {
  flex: 0 1 90px;
  height: 90px;
  width: 90px;
  position: relative;
  filter: grayscale(1);
  transition: 0.4s;
  opacity: 0.4;
  object-fit: contain;

  @media (--mobile) {
    flex: 0 1 60px;
    height: 60px;
    width: 60px;
  }

  & img {
  }

  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}
